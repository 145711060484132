import { FunctionComponent, useEffect } from "react";
import { useIntl } from "react-intl";
import { navigate } from "gatsby";

const NotFoundPage: FunctionComponent = () => {
  const { locale } = useIntl();

  useEffect(() => {
    navigate(`/`, { replace: true });
  }, []);

  return null;
};

export default NotFoundPage;
